import React from "react"
import SEO from './seo'
import HeaderDemolition from './header-demolition'
import FooterDemolition from './footer-demolition'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

export default function LayoutDemolition({ seo, children, env, code, page, bgcolor, footerColor  })
{         
    const bg_color = bgcolor !== undefined ? bgcolor : 'white' 
    const fooC = footerColor !== undefined ? footerColor : ''    
    const main_style =
    {
        background:bg_color
    }
    useScrollPosition(({ prevPos, currPos }) => 
    {
        const showForm = currPos.y < -80;

        const header = document.getElementsByTagName('header');

        header[0].className = showForm === true ? 'showform' : '';
    })
    
    return (
        <div id="wrapper">            

            <SEO seo={seo} />
            
            <header>
                <HeaderDemolition env={env} code={code} page={page}/>                
            </header>

            <main style={main_style}>
                {children}
            </main>
        
            <footer className={fooC}>
                <FooterDemolition />
            </footer> 
        </div>
    ) 
}